// Common
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Optional } from '@angular/core';
import { warmUpObservable } from '@decorators';
import { HttpParamsEncoder } from '../types/http-params-encoder';
import { environment } from '@environment';

// Types
import { BaseSearchResponse } from '../types/base-search-response';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';
import { Constructor } from '../types/constructor';
import { Stitch } from '../types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { StitchType } from '@modules/common/types/stitch-type';
import { KnowledgeFlags } from '../types/knowledge-flags';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { Upload } from '@modules/common/types/upload';
import { File } from '@modules/files/types/file';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { StitchService } from './stitch.service';
import { BaseRestService } from './base-rest.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';

// RX
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BunchCreateFilesOptions } from '@modules/common/types/bunch-create-files-options';

export abstract class BaseStitchService<T extends Stitch, TFilters extends StitchFilters> extends BaseRestService<T, TFilters> {
  protected url: string;
  protected stitchType: StitchType;
  protected filtersConstructor: Constructor<TFilters>;

  constructor(
    protected http: HttpClient,
    protected toaster: ToasterService,
    protected stitchService: StitchService,

    @Optional() protected tagsService: TagsService,
    @Optional() protected knotsService: KnotsService,
    @Optional() protected linkedInfoService: LinkedInfoService,
  ) {
    super(toaster);
  }

  protected static messageForStitchType(stitchType: StitchType, ids: string[]): string {
    return stitchType[0].toUpperCase() + stitchType.substring(1) + (ids?.length === 1 ? ' ' : 's ');
  }

  abstract createInstance(item): T;

  getItem(id: string): Observable<T> {
    return this.getRefresh()
      .pipe(
        startWith(<T> null),
        switchMap(() => this.http.get<{ item: T }>(this.url + id)),
        map(({ item }) => this.createInstance(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  getSharedItem(id: string): Observable<T> {
    return this.http.get<{ item: T }>(`${ this.url }shared/${ id }`)
      .pipe(
        map(({ item }) => this.createInstance(item)),
      );
  }

  search(
    filters?: Partial<TFilters>,
    config?: KnowledgeFlags,
    responseInterceptor?: (response: { count: number, items: T[] }) => { count: number, items: T[] }
  ): Observable<BaseSearchResponse<T>> {

    if (filters?.withEmpty) { return of({ items: [], count: 0 }); }

    const requestParams = { params: new this.filtersConstructor(filters || {}).format() };

    return this.http.get<{ count: number, items: T[] }>(this.url, requestParams)
      .pipe(
        switchMap(({ count, items }) => (
          (
            count > 0 && this.stitchService && (config?.withKnots || config?.withTags || config?.withConnections)
              ? this.stitchService.fillKnowledgeItems(items.map(({ id }) => id ), config)
              : of({})
          )
            .pipe(
              map(knowledge => ({
                count,
                items: items.map(item => ({ ...item, ...(knowledge?.[item.id] || {}) }))
              }))
            )
        )),
        map(({ count, items }) =>
          responseInterceptor ? responseInterceptor({ count, items }) : { count, items }
        ),
        map(({ count, items }) => ({
          count,
          items: items.map(item => this.createInstance(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  deletePermanently(
    filters: Partial<TFilters>,
    { emit = true, toast = true, message = null }: FeedbackConfig = {},
  ): Observable<boolean> {
    const params = new this.filtersConstructor(filters).format();

    return this.http.delete<{ success: boolean }>(this.url, { params })
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({
            text: message || BaseStitchService.messageForStitchType(this.stitchType, filters.ids) + `successfully deleted permanently`
          });
        })
      );
  }

  @warmUpObservable
  public bunchUpdate<TUpdateOptions extends BunchUpdateOptions = BunchUpdateOptions>(
    filters: Partial<TFilters>,
    changes: TUpdateOptions,
    { emit = true, toast = true, message = null }: FeedbackConfig = {},
  ): Observable<void> {
    const params = new this.filtersConstructor(filters).format();

    return this.http.put<{ success: boolean }>(
      this.url,
      { ...changes },
      { params: new HttpParams({ fromObject: params, encoder: new HttpParamsEncoder() }) }
    )
      .pipe(
        map(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Item successfully updated' });
          }
        })
      );
  }

  pin(filters: Partial<TFilters>, pinned: boolean): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ pinned ? '' : 'un' }pinned`;

    return this.bunchUpdate(filters, { pinned }, { message });
  }

  flag(filters: Partial<TFilters>, flagged: boolean): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ flagged ? '' : 'un' }flagged`;

    return this.bunchUpdate(filters, { flagged }, { message });
  }

  archive(filters: Partial<TFilters>, archived: boolean): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ archived ? '' : 'un' }archived`;

    return this.bunchUpdate(filters, { archived }, { message });
  }

  delete(filters: Partial<TFilters>, deleted: boolean): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ deleted ? 'moved to' : 'restored from' } trash`;
    const changes: BunchUpdateOptions = { deleted };
    if (deleted) { changes.archived = false; }

    return this.bunchUpdate(filters, changes, { message });
  }

  followUp(filters: Partial<TFilters>, followed: Date): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ followed ? 'followed' : 'unfollowed' }`;

    return this.bunchUpdate(filters, { followed }, { message });
  }

  snooze(filters: Partial<TFilters>, snoozed: Date): Observable<void> {
    let message = BaseStitchService.messageForStitchType(this.stitchType, filters.ids);
    message += `successfully ${ snoozed ? 'snoozed' : 'unsnoozed' }`;

    return this.bunchUpdate(filters, { snoozed }, { message });
  }

  abstract create(stitchItem: T, config?: FeedbackConfig): Observable<T>;
  abstract update(stitchItem: T, config?: FeedbackConfig): Observable<T>;

  reprocessKnots(items: T[]) {
    return this.http.post<{ success: boolean }>(
      `${ this.url }reprocess-knots`,
      { ids: items.map(i => i.id) }
    )
      .pipe(
        map(({ success }) => {
          success && this.toaster.show({ text: 'Knots reprocessing scheduled' });
        })
      );
  }

  shareViaLink(stitch: Stitch) {
    return this.http.post<{ success: boolean, shareUid: string }>(
      `${ this.url }share/link/${ stitch.id }`,
      {}
    )
      .pipe(
        map(({ shareUid }) => shareUid)
      );
  }

  revokeAccessViaLink(stitch: Stitch) {
    return this.http.delete<{ success: boolean, shareUid: string }>(
      `${ this.url }share/link/${ stitch.id }`
    );
  }

  protected processKnowledgeItems(stitchItem: T, { emit, toast }: FeedbackConfig = { emit: true }): Observable<T> {
    return combineLatest([
      stitchItem.tags?.length
        ? this.tagsService?.upsertBulk(stitchItem.tags, [stitchItem], emit, toast)
        : of(null),
      stitchItem.knots?.length
        ? this.knotsService?.upsertBulk(stitchItem.knots, [stitchItem], emit, toast)
        : of(null),
      stitchItem.linkedInfo?.length
        ? this.linkedInfoService?.linkItems([{ type: stitchItem.getStitchType(), id: stitchItem.id }, ...stitchItem.linkedInfo])
        : of(null)
    ])
      .pipe(map(() => stitchItem));
  }

  processUploads(uploads: Upload[], stitchItem: T, options: BunchCreateFilesOptions = {}, feedbackConfig: FeedbackConfig = { emit: false }): Observable<T> {
    if (!uploads?.length) { of(stitchItem); }

    const files = uploads.map(File.fromUpload);

    return this.bunchCreateFiles(files, options, feedbackConfig)
      .pipe(
        switchMap(files => this.linkedInfoService.linkItems([
          { type: stitchItem.getStitchType(), id: stitchItem.id },
          ...files.map(({ id }) => ({ type: StitchType.file, id }))
        ])),
        map(() => stitchItem)
      );
  }

  private bunchCreateFiles( // storybook does not start if FilesService is injected
    files: File[],
    { other, parentFolderName }: BunchCreateFilesOptions = {},
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<File[]> {
    return this.http.post<{ items: File[], success: boolean }>(
      `${environment.baseUrl}/api/files/files/bulk`,
      {
        files: files.map(file => file.asPayloadJSON()),
        other,
        folderName: parentFolderName
      }
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || 'Files created' });
        }),
        map(({ items }) => items.map(item => new File(item)))
      );
  }
}
