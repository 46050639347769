<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <div
    class="form-heading"
  >
    <app-input
      #titleInput
      class="form-heading-input"
      appearance="sapphire-inline"
      size="s"
      placeholder="Untitled Project"
      [inputFormControl]="form.controls.title"
    />

    <app-priority-input
      [size]="20"
      class="form-heading-priority"
      [control]="form.controls.priority"
    />

    @if(!inline) {
      <mat-icon
        class="form-heading-icon"
        svgIcon="sapphire-chevron-down"
        (click)="close.emit()"
      />
    }
  </div>

  <div class="form-date">
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.fromDate"
      [timeControl]="form.controls.fromTime"
    />
    <stch-date-time-picker
      class="form-date-item"
      [dateControl]="form.controls.toDate"
      [timeControl]="form.controls.toTime"
    />
  </div>

  <app-textarea
    [inputFormControl]="form.controls.description"
    [resize]="false"
    [rows]="2"
    appearance="sapphire-inline"
    placeholder="Description"
  />

  <div class="form-divider"></div>

  <div class="form-buttons">
    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="More options"
        (click)="clickMore()"
      />
    }

    <div class="form-buttons-filler"></div>

    @if(!inline) {
      <app-button
        appearance="sapphire-text-accent"
        size="m"
        label="Cancel"
        (click)="!saveInProgress && cancel()"
      />
    }

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Add"
      (click)="!saveInProgress && submit()"
    />
  </div>
</div>
