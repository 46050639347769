// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Folder } from '@modules/files/types/folder';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { FoldersService } from '@modules/files/services/folders.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-folder-quick-form',
  templateUrl: './folder-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './folder-quick-form.component.less'],
})
export class FolderQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() folder: Folder = new Folder();

  // Outputs
  @Output() more: EventEmitter<Folder> = new EventEmitter<Folder>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Folder> = new EventEmitter();

  constructor (
    private foldersService: FoldersService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('folder' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const folder = Folder.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(folder);
      return;
    }

    this.saveInProgress = true;

    this.foldersService.create(folder)
      .pipe(takeUntil(this.alive))
      .subscribe(
        folderInstance => {
          this.afterSave.emit(folderInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.folder) { return; }
    this.form = this.folder.asFormGroup();
    super.reset();
  }

  clickMore() {
    this.more.emit(Folder.fromFormGroup(this.form));
    super.clickMore();
  }
}
