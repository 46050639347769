// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { RowsFilters } from '../types/rows-filters';
import { Row } from '../types/row';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class RowsService extends BaseRestService<Row, RowsFilters> {

  constructor(
    private http: HttpClient,
    protected toasterService: ToasterService
  ) {
    super(toasterService);
  }

  /**
   * Methods
   */

  search(filters?: Partial<RowsFilters>): Observable<BaseSearchResponse<Row>> {

    if (!filters?.projectsIds?.length) { return of({ items: [], count: 0 }); }

    const requestParams = { params: new RowsFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Row[] }>(
      environment.baseUrl + '/api/tasking/rows',
      requestParams
    )
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new Row(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    rowInstance: Row,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<Row> {
    return this.http.post<{ row: Row, success: boolean }>(`${environment.baseUrl}/api/tasking/rows`, rowInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          success && emit && this.forceRefresh();
          success && toast && this.toasterService.show({ text: message || `Row added.` });
        }),
        map(({ row }) => new Row(row)),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(rowInstance: Row): Observable<Row> {
    return this.http.put<{ row: Row, success: boolean }>(
      environment.baseUrl + '/api/tasking/rows/' + rowInstance.id, rowInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.forceRefresh();
            this.toasterService.show({ text: `Row updated` });
          }
        }),
        map(({ row }) => new Row(row))
      );
  }

  reorder(rows: { id: string, position: number }[], config?: FeedbackConfig): Observable<boolean> {
    return this.http.post<{ success: boolean }>(environment.baseUrl + '/api/tasking/rows/reorder', { rows })
      .pipe(
        tap(({ success }) => {
          success && config?.emit && this.forceRefresh();
        }),
        map(({ success }) => success)
      );
  }

  deletePermanently(rowIds: string[], emitChanges = true): Observable<boolean> {
    return forkJoin(
      rowIds.map(rowId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/tasking/rows/' + rowId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            if (emitChanges) {
              this.forceRefresh();
            }
            this.toasterService.show({ text: `Row(s) successfully deleted.` });
          }
        })
      );
  }

  getAutocompleteSuggestions(defaultFilters: Partial<RowsFilters>): AutocompleteFactory<Row> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters = new RowsFilters({ limit: config?.limit || 5, ...defaultFilters });

      if (values?.length) {
        filters.ids = values;
      }

      if (title) {
        filters.title = title;
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Row>) => response.items.map(row => ({
            title: row.title,
            value: row.id
          })))
        );
    };
  }
}
