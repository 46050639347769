// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { FilesService } from '@modules/files/services/files.service';

// Types
import { File } from '@modules/files/types/file';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-file-context-menu',
  templateUrl: './file-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './file-context-menu.component.less']
})
export class FileContextMenuComponent extends StitchContextMenuComponent<File> implements OnInit, OnChanges {

  @Input() file: File;

  applicationName = Application.files;
  gaPrefix = 'file-context-menu';
  public form: UntypedFormGroup;

  constructor (
    private filesService: FilesService,
    injector: Injector,
  ) {
    super(injector, filesService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.file;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('file' in changes) {
      this.item = this.file;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.filesService.update(File.fromFormGroup(this.form))
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.file || new File()).asFormGroup();
  }

  download() {
    this.file?.download();
  }

  print() {
    this.filesService.print(this.file)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.close());
  }

  duplicate() {
    if (!this.file) { return; }

    const filesToDuplicate = this.getIds();

    this.filesService.duplicate(filesToDuplicate);
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.filesService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`Are you sure you want to remove ${ this.multiple ? 'these' : 'this' } file${ this.multiple && 's'}?`)
  }
}
