// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { Icon } from '@modules/icons/types/icons';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.less'],
})
export class ColorInputComponent {

  public opened = false;
  public palettes = {
    default: [
      '#842D44', '#277A79',
      '#E9590D', '#62A60A',
      '#8C4CD1', '#2864E8',
      '#8f92a0', '#cccfdb',
      '#558b2f', '#aed581'
    ],
    spaces: [
      '#E9590D', '#62A60A',
      '#8C4CD1', '#655AFA',
      '#0395B7', '#63DADA',
      '#52FF63', '#BC425B',
      '#277A79', '#798BBD'
    ]
  };

  @Input() control: FormControl<string>;
  @Input() size = 18; // Only for 'default' appearance
  @Input() icon: Icon; // Only for 'default' appearance
  @Input() appearance: 'default' | 'sapphire' | 'sapphire-inline' = 'default';
  @Input() palette: 'default' | 'spaces' = 'default';

  /**
   * Actions
   */

  handleSelect(color: string) {
    this.control.setValue(color === this.control.value ? null : color);
    this.control.markAsDirty();
  }
}
