// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Modules
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FullCalendarModule } from '@modules/full-calendar/full-calendar.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSliderModule } from 'ngx-slider-v2';
import { PopoverModule } from '@modules/popover/popover.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AttachmentIconComponent } from './components/attachment-icon/attachment-icon.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { AutocompleteMultipleInputComponent } from './components/autocomplete-multiple-input/autocomplete-multiple-input.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { CombinedDropdownInputComponent } from './components/combined-dropdown-input/combined-dropdown-input.component';
import { ControlsListComponent } from './components/controls-list/controls-list.component';
import { CountryPickerComponent } from './components/country-picker/country-picker.component';
import { DateInlineInputComponent } from './components/date-inline-input/date-inline-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DatePicker2Component } from './components/date-picker-2/date-picker-2.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { DateTimePopoverComponent } from './components/date-time-popover/date-time-popover.component';
import { DateTimeRangePickerComponent } from './components/date-time-range-picker/date-time-range-picker.component';
import { DaysContextMenuComponent } from './components/days-context-menu/days-context-menu.component';
import { DropdownBaseComponent } from './elements/dropdown-base/dropdown-base.component';
import { DropdownDateInputComponent } from './components/dropdown-date-input/dropdown-date-input.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { DropdownMonthYearInputComponent } from './components/dropdown-month-year-input/dropdown-month-year-input.component';
import { DropdownMultipleInputComponent } from './components/dropdown-multiple-input/dropdown-multiple-input.component';
import { EditorComponent } from './components/editor/editor.component';
import { FilesInputComponent } from './components/files-input/files-input.component';
import { InputComponent } from './components/input/input.component';
import { InputErrorsComponent } from './components/input-errors/input-errors.component';
import { InputSideOptionsComponent } from './components/input-side-options/input-side-options.component';
import { InputsGroupComponent } from './components/inputs-group/inputs-group.component';
import { KnowledgeButtonComponent } from './components/knowledge-button/knowledge-button.component';
import { MonthsContextMenuComponent } from './components/months-context-menu/months-context-menu.component';
import { NotificationPickerComponent } from './components/notification-picker/notification-picker.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { PriorityInputComponent } from './components/priority-input/priority-input.component';
import { QuickActionComponent } from './components/quick-action/quick-action.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RecurrencePickerComponent } from './components/recurrence-picker/recurrence-picker.component';
import { RemindersPickerComponent } from './components/reminders-picker/reminders-picker.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { SimpleInputComponent } from './components/simple-input/simple-input.component';
import { SliderComponent } from './components/slider/slider.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { TimeRangePickerComponent } from './components/time-range-picker/time-range-picker.component';
import { TimeZoneInputComponent } from './components/timezone-input/timezone-input.component';
import { TitleInputComponent } from './components/title-input/title-input.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { WorkWeekPickerComponent } from './components/work-week-picker/work-week-picker.component';
import { YearsContextMenuComponent } from './components/years-context-menu/years-context-menu.component';

// Elements
import { DatePickerComponent } from './elements/date-picker/date-picker.component';
import { DateTimePickerComponent } from './elements/date-time-picker/date-time-picker.component';
import { DropdownContainerComponent } from './elements/dropdown-container/dropdown-container.component';
import { DropdownItemComponent } from './elements/dropdown-item/dropdown-item.component';
import { RecurrenceDropdownComponent } from './elements/recurrence-dropdown/recurrence-dropdown.component';
import { RecurrenceFormComponent } from './elements/recurrence-form/recurrence-form.component';
import { RemindersDropdownComponent } from './elements/reminders-dropdown/reminders-dropdown.component';
import { TimeSelectorComponent } from './elements/time-selector/time-selector.component';
import { UploadComponent } from './elements/upload/upload.component';

// Directives
import { DaysContextMenuDirective } from './directives/days-context-menu.directive';
import { MonthsContextMenuDirective } from './directives/months-context-menu.directive';
import { YearsContextMenuDirective } from './directives/years-context-menu.directive';

// Pipes
import { ActiveRemindersOptionPipe } from './pipes/active-reminders-option.pipe';
import { DisableQuickDatePickerPipe } from './pipes/disable-quick-date-picker.pipe';
import { NotificationTextPipe } from './pipes/notification-text.pipe';

// TODO AttachmentIconComponent is placed here as lesser-evil to avoid circular dependency. Find better place for it.

@NgModule({
  imports: [
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    BundledInputsModule,
    CommonModule,
    DragNDropModule,
    EditorModule,
    FullCalendarModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    NgxSliderModule,
    PopoverModule,
    ReactiveFormsModule,
    UserAvatarModule,
    UtilsModule,
  ],
  declarations: [
    AttachmentIconComponent,
    AutocompleteInputComponent,
    AutocompleteMultipleInputComponent,
    ButtonComponent,
    CheckboxComponent,
    CircularProgressComponent,
    ColorInputComponent,
    ColorPickerComponent,
    CombinedDropdownInputComponent,
    ControlsListComponent,
    CountryPickerComponent,
    DateInlineInputComponent,
    DateInputComponent,
    DatePicker2Component,
    DateTimeComponent,
    DateTimePickerComponent,
    DateTimePopoverComponent,
    DateTimeRangePickerComponent,
    DaysContextMenuComponent,
    DropdownBaseComponent,
    DropdownDateInputComponent,
    DropdownInputComponent,
    DropdownMonthYearInputComponent,
    DropdownMultipleInputComponent,
    EditorComponent,
    FilesInputComponent,
    InputComponent,
    InputErrorsComponent,
    InputSideOptionsComponent,
    InputsGroupComponent,
    KnowledgeButtonComponent,
    MonthsContextMenuComponent,
    NotificationPickerComponent,
    PhoneNumberInputComponent,
    PriorityInputComponent,
    QuickActionComponent,
    RadioButtonComponent,
    RecurrencePickerComponent,
    RemindersPickerComponent,
    RichTextEditorComponent,
    SimpleInputComponent,
    SliderComponent,
    TextareaComponent,
    TimeInputComponent,
    TimePickerComponent,
    TimeRangePickerComponent,
    TimeZoneInputComponent,
    TitleInputComponent,
    ToggleSwitchComponent,
    WorkWeekPickerComponent,
    YearsContextMenuComponent,

    // Elements
    DatePickerComponent,
    DropdownContainerComponent,
    DropdownItemComponent,
    RecurrenceDropdownComponent,
    RecurrenceFormComponent,
    RemindersDropdownComponent,
    TimeSelectorComponent,
    UploadComponent,

    // Directives
    DaysContextMenuDirective,
    MonthsContextMenuDirective,
    YearsContextMenuDirective,

    // Pipes
    ActiveRemindersOptionPipe,
    DisableQuickDatePickerPipe,
    NotificationTextPipe
  ],
  exports: [
    AttachmentIconComponent,
    AutocompleteInputComponent,
    AutocompleteMultipleInputComponent,
    ButtonComponent,
    CheckboxComponent,
    CircularProgressComponent,
    ColorInputComponent,
    ColorPickerComponent,
    CombinedDropdownInputComponent,
    ControlsListComponent,
    CountryPickerComponent,
    DateInlineInputComponent,
    DateInputComponent,
    DatePicker2Component,
    DateTimeComponent,
    DateTimePickerComponent,
    DateTimePopoverComponent,
    DateTimeRangePickerComponent,
    DropdownDateInputComponent,
    DropdownInputComponent,
    DropdownMonthYearInputComponent,
    DropdownMultipleInputComponent,
    EditorComponent,
    FilesInputComponent,
    InputComponent,
    InputErrorsComponent,
    InputSideOptionsComponent,
    InputsGroupComponent,
    KnowledgeButtonComponent,
    PhoneNumberInputComponent,
    PriorityInputComponent,
    QuickActionComponent,
    RadioButtonComponent,
    RecurrencePickerComponent,
    RemindersPickerComponent,
    RichTextEditorComponent,
    SimpleInputComponent,
    SliderComponent,
    TextareaComponent,
    TimePickerComponent,
    TimeRangePickerComponent,
    TimeZoneInputComponent,
    TitleInputComponent,
    ToggleSwitchComponent,
    WorkWeekPickerComponent,

    // Elements
    RecurrenceFormComponent,

    // Directives
    DaysContextMenuDirective,
    MonthsContextMenuDirective,
    YearsContextMenuDirective
  ],
  providers: []
})
export class FormControlsModule { }
